import authHeader from '../../utils/authHeader'
import authApi from '../../utils/AxiosConfig'

const API_URL = `${process.env.API_URL}/api/v1/buyer/projects`

// Create a new project
const createBuyerProject = async (formData: any) => {
  const response = await authApi.post(API_URL + '/', formData, {
    headers: authHeader(),
  })
  return response.data
}
// get all user projects
const getAllBuyerProjects = async () => {
  const response = await authApi.get(API_URL + '/', { headers: authHeader() })
  return response.data
}

// get one project
const getBuyerProject = async (id: string) => {
  const response = await authApi.get(API_URL + '/' + id, {
    headers: authHeader(),
  })
  return response.data
}

// delete
const deleteUserProject = async (id: string) => {
  const response = await authApi.delete(API_URL + '/' + id, {
    headers: authHeader(),
  })
  return response.data
}

const buyerProjectService = {
  createBuyerProject,
  getAllBuyerProjects,
  getBuyerProject,
  deleteUserProject,
}

export default buyerProjectService
