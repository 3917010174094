import buyerProjectService from './buyerProjectService'
import { RootState } from '../index'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'

interface IinitialState {
  step1Data: any
  step2Data: any
  currentStep: number
  isError: boolean
  isSuccess: boolean
  isLoading: boolean
  message: any
}

const initialState: IinitialState = {
  step1Data: {},
  step2Data: {},
  currentStep: 1,
  userProjects: [],
  project: {},
}

export const createBuyerProject = createAsyncThunk<
  any,
  any,
  { state: RootState }
>('buyerProjects/createBuyerProject', async (data, thunkAPI) => {
  const { step1Data, step2Data } = thunkAPI.getState().buyerProjects
  try {
    const formData = {
      step1Data,
      step2Data,
      step3Data: data,
    }
    return await buyerProjectService.createBuyerProject(formData)
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString()
    toast.error(message)
    return thunkAPI.rejectWithValue(message)
  }
})
export const getAllBuyerProjects = createAsyncThunk<
  any,
  any,
  { state: RootState }
>('buyerProjects/getAllBuyerProjects', async (_, thunkAPI) => {
  try {
    return await buyerProjectService.getAllBuyerProjects()
  } catch (error: any) {
    console.log(error)
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString()
    toast.error(message)
    return thunkAPI.rejectWithValue(message)
  }
})

export const getBuyerProject = createAsyncThunk<any, any, { state: RootState }>(
  'buyerProjects/getBuyerProject',
  async (id, thunkAPI) => {
    try {
      return await buyerProjectService.getBuyerProject(id)
    } catch (error: any) {
      console.log(error)
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteUserProject = createAsyncThunk<
  any,
  any,
  { state: RootState }
>('buyerProjects/deleteUserProject', async (id, thunkAPI) => {
  try {
    return await buyerProjectService.deleteUserProject(id)
  } catch (error: any) {
    console.log(error)
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const buyerProjectsSlice = createSlice({
  name: 'buyerProjects',
  initialState,
  reducers: {
    reset: (state) => {
      state.currentStep = 1
      state.step1Data = {}
      state.step2Data = {}
    },
    setStep1: (state, { payload }) => {
      state.step1Data = payload
      state.currentStep = 2
    },
    setStep2: (state, { payload }) => {
      state.step2Data = payload
      state.currentStep = 3
    },
    setCurrentStep: (state, { payload }) => {
      state.currentStep = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBuyerProject.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createBuyerProject.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isSuccess = true
        state.project = payload.data
      })
      .addCase(createBuyerProject.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getAllBuyerProjects.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllBuyerProjects.fulfilled, (state, { payload }) => {
        state.userProjects = payload.data
      })
      .addCase(getAllBuyerProjects.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isError = true
        state.message = payload
      })
      .addCase(getBuyerProject.pending, (state) => {
        state.isLoading = true
      })

      .addCase(getBuyerProject.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isSuccess = true
        state.project = payload.data[0]
      })

      .addCase(getBuyerProject.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })

      .addCase(deleteUserProject.pending, (state) => {
        state.isLoading = true
      })

      .addCase(deleteUserProject.fulfilled, (state) => {
        state.isLoading = false
        state.isSuccess = true
      })

      .addCase(deleteUserProject.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

// Actions
export const { reset, setStep1, setStep2, setCurrentStep } =
  buyerProjectsSlice.actions
export default buyerProjectsSlice.reducer
