import authHeader from '../../utils/authHeader'
import authApi from '../../utils/AxiosConfig'

const API_URL = `${process.env.API_URL}/api/v1/admin/projects`

// Get all admin projects
const getAllAdminProjects = async () => {
  const response = await authApi.get(API_URL + '/', { headers: authHeader() })
  return response.data
}

// Get one project by ID
const getAdminProject = async (id: string) => {
  const response = await authApi.get(API_URL + '/' + id, {
    headers: authHeader(),
  })
  return response.data
}

// Update project status
const updateAdminProjectStatus = async (id: string, status: string) => {
  const response = await authApi.patch(
    `${API_URL}/${id}/status`,
    { status }, // Sending the status in the body
    {
      headers: authHeader(),
    }
  )
  return response.data
}

// Delete a project
const deleteAdminProject = async (id: string) => {
  const response = await authApi.delete(API_URL + '/' + id, {
    headers: authHeader(),
  })
  return response.data
}

// Approve a project
const approveAdminProject = async (id: string) => {
  const response = await authApi.patch(
    `${API_URL}/${id}/approve`, // Assuming an endpoint for approving
    {},
    {
      headers: authHeader(),
    }
  )
  return response.data
}

// Reject a project
const rejectAdminProject = async (id: string) => {
  const response = await authApi.patch(
    `${API_URL}/${id}/reject`, // Assuming an endpoint for rejecting
    {},
    {
      headers: authHeader(),
    }
  )
  return response.data
}

const adminProjectService = {
  getAllAdminProjects,
  getAdminProject,
  deleteAdminProject,
  updateAdminProjectStatus,
  approveAdminProject,
  rejectAdminProject,
}

export default adminProjectService
