import adminProjectService from './adminProjectService'
import { RootState } from '../index'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'

interface IinitialState {
  isError: boolean
  isSuccess: boolean
  isLoading: boolean
  message: any
  userProjects: any[]
  project: any
}

const initialState: IinitialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: null,
  userProjects: [],
  project: {},
}

// Get all admin projects
export const getAllAdminProjects = createAsyncThunk<
  any,
  any,
  { state: RootState }
>('adminProjects/getAllAdminProjects', async (_, thunkAPI) => {
  try {
    return await adminProjectService.getAllAdminProjects()
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString()
    toast.error(message)
    return thunkAPI.rejectWithValue(message)
  }
})

// Get a specific admin project
export const getAdminProject = createAsyncThunk<any, any, { state: RootState }>(
  'adminProjects/getAdminProject',
  async (id, thunkAPI) => {
    try {
      return await adminProjectService.getAdminProject(id)
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Delete a project
export const deleteAdminProject = createAsyncThunk<
  any,
  any,
  { state: RootState }
>('adminProjects/deleteAdminProject', async (id, thunkAPI) => {
  try {
    return await adminProjectService.deleteAdminProject(id)
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString()
    toast.error(message)
    return thunkAPI.rejectWithValue(message)
  }
})

// Update the project status
export const updateAdminProjectStatus = createAsyncThunk<
  any,
  { id: string; status: string },
  { state: RootState }
>(
  'adminProjects/updateAdminProjectStatus',
  async ({ id, status }, thunkAPI) => {
    try {
      return await adminProjectService.updateAdminProjectStatus(id, status)
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString()
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Approve the project
export const approveAdminProject = createAsyncThunk<
  any,
  string,
  { state: RootState }
>('adminProjects/approveAdminProject', async (id, thunkAPI) => {
  try {
    return await adminProjectService.approveAdminProject(id)
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString()
    toast.error(message)
    return thunkAPI.rejectWithValue(message)
  }
})

// Reject the project
export const rejectAdminProject = createAsyncThunk<
  any,
  string,
  { state: RootState }
>('adminProjects/rejectAdminProject', async (id, thunkAPI) => {
  try {
    return await adminProjectService.rejectAdminProject(id)
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString()
    toast.error(message)
    return thunkAPI.rejectWithValue(message)
  }
})

export const adminProjectsSlice = createSlice({
  name: 'adminProjects',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false
      state.isSuccess = false
      state.isLoading = false
      state.message = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAdminProjects.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllAdminProjects.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.userProjects = payload.data
      })
      .addCase(getAllAdminProjects.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isError = true
        state.message = payload
      })
      .addCase(getAdminProject.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAdminProject.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.project = action.payload.data
      })
      .addCase(getAdminProject.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteAdminProject.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteAdminProject.fulfilled, (state) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(deleteAdminProject.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      // Approve project
      .addCase(approveAdminProject.pending, (state) => {
        state.isLoading = true
      })
      .addCase(approveAdminProject.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.project = action.payload.data
        toast.success('Project approved successfully')
      })
      .addCase(approveAdminProject.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      // Reject project
      .addCase(rejectAdminProject.pending, (state) => {
        state.isLoading = true
      })
      .addCase(rejectAdminProject.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.project = action.payload.data
        toast.success('Project rejected successfully')
      })
      .addCase(rejectAdminProject.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

// Actions
export const { reset } = adminProjectsSlice.actions
export default adminProjectsSlice.reducer
