import { useSelector } from 'react-redux'
import LoadingToRedirect from './LoadingToRedirect'
import { useEffect } from 'react'
import { RootState } from '../store'

const { auth } = JSON.parse(localStorage.getItem('persist:root') || '{}')

const PrivateRoute = ({ children }) => {
  const { token } = useSelector((state: RootState) => state.auth || auth)
  useEffect(() => {
    if (!token || token === 'undefined') {
      localStorage.removeItem('presist:root')
      window.location.href = '/auth/login'
    }
  }, [token])

  return token ? children : <LoadingToRedirect />
}

export default PrivateRoute
