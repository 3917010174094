import { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'

const LoadingToRedirect = () => {
  const [count, setCount] = useState(1)
  const navigate = useNavigate()

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => currentCount - 1)
    }, 1000)

    count === 0 && navigate('/auth/login')

    return () => clearInterval(interval)
  }, [count, navigate])

  return (
    <div className="flex h-screen">
      <div className="m-auto text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="mx-auto h-16 w-16 text-purple-500 dark:text-white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
          />
        </svg>
        <h3 className="mt-2 text-2xl font-medium text-gray-900 dark:text-white">
          Logging you out
        </h3>
        <p className="mt-1 text-xl text-gray-500 dark:text-white">
          Redirecting you in {count} sec
        </p>
        <div className="mt-6">
          <Link
            to="/auth/login"
            className="inline-flex items-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="-ml-1 mr-2 h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
            Go Home
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LoadingToRedirect
