import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import authService from './authService'
import { RootState } from '..'
import toast from 'react-hot-toast'
import stripeService from '../stripe/stripeService'

interface IinitialState {
  user: object | null
  token: string | null
  isError: boolean
  isSuccess: boolean
  isLoading: boolean
  isVerified: boolean
  isValidated: boolean
  message: any
}

// Assuming 'persist:root' is the correct key for your Redux Persist configuration
const persistedState = JSON.parse(localStorage.getItem('persist:root') || '{}')

// Access the 'auth' reducer state
const auth = JSON.parse(persistedState?.auth || '{}')

const initialState: IinitialState = {
  user: auth.user ? auth.user : null,
  token: auth.token ? auth.token : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  isVerified: false,
  isValidated: false,
  message: '',
}

export const userSignup = createAsyncThunk(
  'auth/register',
  async (user, thunkAPI) => {
    try {
      return await authService.register(user)
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message &&
          error.response.data.error) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
  try {
    return await authService.login(user)
  } catch (error: any) {
    console.log(error.response.data.error)
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const logout = createAsyncThunk<any, any, { state: RootState }>(
  'auth/logout',
  async (thunkAPI) => {
    try {
      return await authService.logout()
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString() ||
        error.error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (email: any, thunkAPI) => {
    try {
      return await authService.forgotPassword(email)
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString() ||
        error.error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const verifyPasswordResetToken = createAsyncThunk(
  'auth/verifyPasswordResetToken',
  async ({ token, id }, thunkAPI) => {
    try {
      return await authService.verifyPasswordResetToken({ token, id })
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString() ||
        error.error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (data: any, thunkAPI) => {
    try {
      return await authService.resetPassword(data)
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString() ||
        error.error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const verifyEmail = createAsyncThunk(
  'auth/verify-email',
  async (data, thunkAPI) => {
    try {
      return await authService.verifyEmail(data)
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString() ||
        error.error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const resendVerifyEmail = createAsyncThunk(
  'auth/resend-verify-email',
  async (_, thunkAPI: { rejectWithValue: (value: string) => void }) => {
    try {
      return await authService.resendVerifyEmail()
    } catch (error: any) {
      console.log('error', error)

      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString() ||
        error.error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getMe = createAsyncThunk('auth/getMe', async (_, thunkAPI) => {
  try {
    return await authService.getMe()
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString() ||
      error.error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const deleteMe = createAsyncThunk(
  'auth/deleteMe',
  async (_, thunkAPI: { rejectWithValue: (value: string) => void }) => {
    try {
      return await authService.deleteMe()
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString() ||
        error.error.toString()
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getUserProfile = createAsyncThunk(
  'auth/getUserProfile',
  async (_, thunkAPI) => {
    try {
      return await authService.getUserProfile()
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString() ||
        error.error.toString()
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateUserProfile = createAsyncThunk(
  'auth/updateUserProfile',
  async (formData, thunkAPI) => {
    try {
      return await authService.updateUserProfile(formData)
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString() ||
        error.error.toString()
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getStripeStatus = createAsyncThunk(
  'stripe/status',
  async (_, thunkAPI) => {
    try {
      return await stripeService.statusStripe()
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString() ||
        error.error.toString()
      console.log(message)
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false
      state.isSuccess = false
      state.isLoading = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMe.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMe.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = payload.data
      })
      .addCase(getMe.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.user = null
        state.token = null
        state.message = action.payload
      })
      .addCase(userSignup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(userSignup.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.token = action.payload.token
        state.user = action.payload.user
      })
      .addCase(userSignup.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
        state.token = null
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.token = action.payload.token
        state.user = action.payload.user
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
        state.token = null
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null
        state.token = null
      })
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload.message
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteMe.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteMe.fulfilled, (state) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = null
        state.token = null
      })
      .addCase(deleteMe.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(verifyEmail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload.user
        state.message = action.payload.message
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(verifyPasswordResetToken.pending, (state) => {
        state.isLoading = true
        state.isVerified = false
      })
      .addCase(verifyPasswordResetToken.fulfilled, (state) => {
        state.isLoading = false
        state.isVerified = true
      })
      .addCase(verifyPasswordResetToken.rejected, (state, action) => {
        state.isLoading = false
        state.isVerified = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(resendVerifyEmail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(resendVerifyEmail.fulfilled, (state) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(resendVerifyEmail.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getStripeStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getStripeStatus.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = payload.data
      })
      .addCase(getStripeStatus.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isError = true
        state.message = payload
      })
  },
})

export const { reset } = authSlice.actions
export default authSlice.reducer
