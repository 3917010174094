import authApi from '../../utils/AxiosConfig'
import authHeader from '../../utils/authHeader'

// Register
const register = async (formData: any) => {
  const response = await authApi.post('/auth/register', formData)
  return response.data
}

// Login
const login = async (formData: any) => {
  const response = await authApi.post('/auth/login', formData)
  return response.data
}

// Logout
const logout = async () => {
  const response = await authApi.post('/auth/logout')
  if (response.data) {
    localStorage.removeItem('presist:root')
  }
}

const forgotPassword = async (formData: any) => {
  const response = await authApi.post('/auth/forgot-password', formData)
  return response.data
}

const verifyPasswordResetToken = async ({ token, id }: any) => {
  const response = await authApi.post('/auth/verify-password-reset-token', {
    token,
    id,
  })
  return response.data
}

const resetPassword = async (formData: any) => {
  const response = await authApi.post('/auth/reset-password/', formData)
  return response.data
}

const verifyEmail = async (formData: any) => {
  const response = await authApi.post('/auth/verify-email', formData, {
    headers: authHeader(),
  })

  return response.data
}

const resendVerifyEmail = async () => {
  const response = await authApi.post(
    '/auth/resend-verification-email',
    {},
    {
      headers: authHeader(),
    }
  )
  return response.data
}

const getMe = async () => {
  const response = await authApi.post(
    '/auth/me',
    {},
    {
      headers: authHeader(),
    }
  )
  return response.data
}

const deleteMe = async () => {
  const response = await authApi.post(
    '/auth/delete-account',
    {},
    {
      headers: authHeader(),
    }
  )
  if (response.data) {
    localStorage.removeItem('persist:root')
  }
  return response.data
}

const getUserProfile = async () => {
  const response = await authApi.get('/auth/profile', {
    headers: authHeader(),
  })
  return response.data
}

const updateUserProfile = async (formData: any) => {
  const response = await authApi.put('/auth/profile', formData, {
    headers: authHeader(),
  })
  return response.data
}

const authService = {
  login,
  register,
  logout,
  getMe,
  forgotPassword,
  resetPassword,
  verifyEmail,
  resendVerifyEmail,
  verifyPasswordResetToken,
  deleteMe,
  getUserProfile,
  updateUserProfile,
}

export default authService
