import { useSelector } from 'react-redux'
import LoadingToRedirect from './LoadingToRedirect'

const SellerRedirect = ({ children }) => {
  const { user } = useSelector((state) => state.auth)
  return user?.role === 'ROLE_SELLER' || user?.role === 'ROLE_ADMIN' ? (
    children
  ) : (
    <LoadingToRedirect />
  )
}

export default SellerRedirect
